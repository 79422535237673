import { createGlobalStyle, keyframes } from 'styled-components/macro';

const fadeIn = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

const BaseStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Roboto Slab', serif;
  }
  html, body {
    background-color: ${props => props.theme.shade200};
    color: ${props => props.theme.shade700};
    width: 100%;
    width: 100vw;
  }
  a {
    text-decoration: none;
    &:focus{
      outline: none;
      /* text-decoration: underline; */
    }
  }
  button, a {
    display: inline-block;
    appearance: none;
    outline: none;
    border: 2px solid ${({ theme }) => theme.shade500};
    border-radius: 6px;
    background: rgba(232, 232, 231, 0.2);
    padding: 8px 16px;
    cursor: pointer;
    color: ${({ theme }) => theme.shade700};
    strong{
      color: ${({ theme }) => theme.shade800};
    }
    &:hover, &:focus{
      outline: none;
      border: 2px solid ${({ theme }) => theme.shade600};
      background: rgba(232, 232, 231, 0.3);
      color: ${({ theme }) => theme.shade800};
      strong{
        color: ${({ theme }) => theme.shade900};
      }
    }
  }
  .hidden-mobile {
    @media(max-width: 1023px){
      display: none !important;
    }
  }
  .shown-mobile {
    @media(min-width: 1024px){
      display: none !important;
    }
  }
  .fade-in {
    opacity: 0;
    animation: ${fadeIn} 400ms ${({ theme }) => theme.easeDecelerate} 150ms forwards;
  }

`;
export default BaseStyles;
