import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import Spacer from '../../common/Spacer';
import CrookedLittleHouseCoverImg from '../../img/crooked-little-house-cover.jpg';
import usePageView from '../../hooks/usePageView';

// Styles
const pageFadeIn = keyframes`
    0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
`;
const PageWrapper = styled.div`
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  width: 100vw;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;

  margin: 0 auto;
  padding: 180px calc((100% - 768px) / 2) 64px;
  @media (max-width: 896px) {
    padding: 128px 64px 48px;
  }
  @media (max-width: 512px) {
    padding: 96px 16px 32px;
  }

  background-color: ${({ theme }) => theme.shade100};
  opacity: 0;
  transform: translateY(20px);
  animation: ${pageFadeIn} 800ms ${({ theme }) => theme.easeDecelerate} 500ms forwards;
`;
const CloseButton = styled(Link)`
  position: fixed;
  top: 32px;
  right: 48px;
  @media (max-width: 512px) {
    top: 16px;
    right: 16px;
  }
`;
const Expanded = styled.div`
  flex: 1;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 24px;
`;
const AlbumImg = styled.img`
  width: 96px;
  height: 96px;
  margin-right: 24px;
  @media (max-width: 512px) {
    width: 64px;
    height: 64px;
    margin-right: 12px;
  }

  box-shadow: 0px 0.67932px 2.44555px rgba(0, 0, 0, 0.0243888),
    0px 1.87823px 6.76164px rgba(0, 0, 0, 0.035), 0px 4.52206px 16.2794px rgba(0, 0, 0, 0.0456112),
    0px 15px 54px rgba(0, 0, 0, 0.07);
`;
const TitleRow = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
  @media (max-width: 768px) {
    padding-bottom: 12px;
  }
  @media (max-width: 512px) {
    padding-bottom: 8px;
  }

  border-bottom: 4px solid ${({ theme }) => theme.shade200};
`;
const TitleSubhead = styled.h2`
  margin: 0;

  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.04em;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 512px) {
    font-size: 16px;
    line-height: 22px;
  }

  color: ${({ theme }) => theme.shade500};
`;
const TitleHead = styled.h1`
  margin: 0;

  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (max-width: 512px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Description = styled.div`
  text-align: justify;
  color: ${({ theme }) => theme.shade600};

  font-size: 18px;
  line-height: 28px;
  @media (max-width: 512px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const AvailableOnRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  border-top: 4px solid ${({ theme }) => theme.shade200};
  border-bottom: 4px solid ${({ theme }) => theme.shade200};
  margin-bottom: 48px;
  padding: 8px 0;
  a {
    display: block;
    margin-left: 24px;
    @media (max-width: 768px) {
      margin-left: 16px;
    }
  }
`;
const AvailableOnHeader = styled.div`
  color: ${({ theme }) => theme.shade500};
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.04em;
  flex: 1;
  white-space: nowrap;
`;

const TrackRow = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  margin-bottom: 8px;
`;

const TrackTitle = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-weight: normal;

  color: ${({ theme }) => theme.shade500};
  font-size: 16px;
`;
const TrackNumber = styled(TrackTitle)`
  flex: none;
  color: ${({ theme }) => theme.shade400};

  margin-right: 16px;
`;
const Timestamp = styled.div`
  margin-left: 16px;
  font-variant: tabular-nums;
  color: ${({ theme }) => theme.shade400};

  font-size: 14px;
`;

// Component
const CrookedLittleHouse = () => {
  usePageView('*ville | Crooked Little House - Revisited');

  return (
    <PageWrapper>
      <CloseButton to='/'>
        <strong>X</strong>&nbsp;&nbsp;Close
      </CloseButton>
      {/* Title ( w/ album art ) */}
      <TitleWrapper>
        <AlbumImg src={CrookedLittleHouseCoverImg} alt='Crooked Little House - Cover Art' />
        <TitleRow>
          <Expanded>
            <TitleSubhead>Album</TitleSubhead>
            <TitleHead>Crooked Little House - Revisited</TitleHead>
          </Expanded>
          <Timestamp>30:28</Timestamp>
        </TitleRow>
      </TitleWrapper>

      {/* Description */}
      <Description>
        The original <strong>Crooked Little House EP</strong> was released in the fall of 2010 to
        mixed reviews. Yes—it had all the feels... hope, dispair, love, longing, sorrow, etc. But
        what can you expect? After all, it was recorded in a house that was, well... crooked.
      </Description>
      <Spacer height='24px' />
      <Description>
        <strong>Crooked Little House - Revisited</strong> is a retrospective re-release establishing
        a fresh new take on the band’s dream years at SW Jennings Ave. It’s kinda funny... only in
        hindsight (and without the beer-goggles) could this album become what it was always meant to
        be.
      </Description>
      <Spacer height='32px' />

      {/* Available on... */}
      <AvailableOnRow>
        <AvailableOnHeader>Available on:</AvailableOnHeader>
        <a
          href='https://open.spotify.com/album/02rRT2GTGM66LmFzQJkUDw'
          target='_blank'
          rel='noopener noreferrer'
        >
          Spotify
        </a>
        <a
          href='https://music.apple.com/us/album/crooked-little-house-revisited/1512500499?uo=4'
          target='_blank'
          rel='noopener noreferrer'
        >
          Apple Music
        </a>
        {/* <a href='http://www.deezer.com/album/147191222' target='_blank' rel='noopener noreferrer'>
          Deezer
        </a> */}
        {/* <a href='http://www.tidal.com/album/140710035' target='_blank' rel='noopener noreferrer'>
          Tidal
        </a> */}
      </AvailableOnRow>

      {/* Tracks */}
      <TrackRow>
        {/* 1 - Still 22 */}
        <TrackNumber>01.</TrackNumber>
        <TrackTitle>Still 22</TrackTitle>
        <Timestamp>04:09</Timestamp>
      </TrackRow>
      {/* 2 - Cloud Song */}
      <TrackRow>
        <TrackNumber>02.</TrackNumber>
        <TrackTitle>Cloud Song</TrackTitle>
        <Timestamp>04:12</Timestamp>
      </TrackRow>
      {/* 3 - Crooked Little House */}
      <TrackRow>
        <TrackNumber>03.</TrackNumber>
        <TrackTitle>Crooked Little House</TrackTitle>
        <Timestamp>04:44</Timestamp>
      </TrackRow>
      {/* 4 - Money */}
      <TrackRow>
        <TrackNumber>04.</TrackNumber>
        <TrackTitle>Money</TrackTitle>
        <Timestamp>04:48</Timestamp>
      </TrackRow>
      {/* 5 - Son */}
      <TrackRow>
        <TrackNumber>05.</TrackNumber>
        <TrackTitle>Son</TrackTitle>
        <Timestamp>03:42</Timestamp>
      </TrackRow>
      {/* 6 - Tony, Tracy, and Me */}
      <TrackRow>
        <TrackNumber>06.</TrackNumber>
        <TrackTitle>Tony, Tracy, and Me</TrackTitle>
        <Timestamp>03:49</Timestamp>
      </TrackRow>
      {/* 7 - Never Bigger Than You */}
      <TrackRow>
        <TrackNumber>07.</TrackNumber>
        <TrackTitle>Never Bigger Than You</TrackTitle>
        <Timestamp>04:59</Timestamp>
      </TrackRow>
    </PageWrapper>
  );
};
export default CrookedLittleHouse;
