import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/analytics';

const config = {
  apiKey: 'AIzaSyCj2foB5SjPp5lK1lF0ApDiS8eKbBBR99Y',
  authDomain: 'ville-band.firebaseapp.com',
  projectId: 'ville-band',
  appId: '1:647146726578:web:8d16ea5184af026a7715ab',
  measurementId: 'G-PYD8Y4GRQF',
};

// Initialize firebase (if isn't already)
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// Initialize Performance Monitoring through Firebase
export const perf = firebase.performance();

// Initialize Analytics through Firebase
export const analytics = firebase.analytics();

export default firebase;
