import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  ////////////
  // Colors //
  ////////////
  shade000: '#F1F1F1',
  shade100: '#E8E8E7',
  shade200: '#E2DFDE',
  shade300: '#D4D0CE',
  shade400: '#C3BDBB',
  shade500: '#A19D9B',
  shade600: '#888381',
  shade700: '#6D6A69',
  shade800: '#3A3736',
  shade900: '#252322',

  /////////////
  // Shadows //
  /////////////
  // shadow100: '0px 1px 3px rgba(62, 76, 89, 0.12)',

  ////////////
  // Easing //
  ////////////
  // Adapted from Google Material Motion
  // https:/'/material.io/guidelines/motion/duration-easing.html#duration-easing-natural-easing-curve's
  ////////////
  easeStandard: 'cubic-bezier(0.4, 0, 0.2, 1)',
  easeSharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
  easeDecelerate: 'cubic-bezier(0, 0, 0.2, 1)',
  easeAccelerate: 'cubic-bezier(0.4, 0, 1, 1)',
};

export default defaultTheme;
