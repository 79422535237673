import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import Spacer from '../../common/Spacer';
import NameCoverImg from '../../img/name-cover.jpg';
import usePageView from '../../hooks/usePageView';

// Styles
const pageFadeIn = keyframes`
    0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
`;
const PageWrapper = styled.div`
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  width: 100vw;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;

  margin: 0 auto;
  padding: 180px calc((100% - 768px) / 2) 64px;
  @media (max-width: 896px) {
    padding: 128px 64px 48px;
  }
  @media (max-width: 512px) {
    padding: 96px 16px 32px;
  }

  background-color: ${({ theme }) => theme.shade100};
  opacity: 0;
  transform: translateY(20px);
  animation: ${pageFadeIn} 800ms ${({ theme }) => theme.easeDecelerate} 500ms forwards;
`;
const CloseButton = styled(Link)`
  position: fixed;
  top: 32px;
  right: 48px;
  @media (max-width: 512px) {
    top: 16px;
    right: 16px;
  }
`;
const Expanded = styled.div`
  flex: 1;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 24px;
`;
const AlbumImg = styled.img`
  width: 96px;
  height: 96px;
  margin-right: 24px;
  @media (max-width: 512px) {
    width: 64px;
    height: 64px;
    margin-right: 12px;
  }

  box-shadow: 0px 0.67932px 2.44555px rgba(0, 0, 0, 0.0243888),
    0px 1.87823px 6.76164px rgba(0, 0, 0, 0.035), 0px 4.52206px 16.2794px rgba(0, 0, 0, 0.0456112),
    0px 15px 54px rgba(0, 0, 0, 0.07);
`;
const TitleRow = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
  @media (max-width: 768px) {
    padding-bottom: 12px;
  }
  @media (max-width: 512px) {
    padding-bottom: 8px;
  }

  border-bottom: 4px solid ${({ theme }) => theme.shade200};
`;
const TitleSubhead = styled.h2`
  margin: 0;

  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.04em;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 512px) {
    font-size: 16px;
    line-height: 22px;
  }

  color: ${({ theme }) => theme.shade500};
`;
const TitleHead = styled.h1`
  margin: 0;

  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (max-width: 512px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Description = styled.div`
  text-align: justify;
  color: ${({ theme }) => theme.shade600};

  font-size: 18px;
  line-height: 28px;
  @media (max-width: 512px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const AvailableOnRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  border-top: 4px solid ${({ theme }) => theme.shade200};
  border-bottom: 4px solid ${({ theme }) => theme.shade200};
  margin-bottom: 48px;
  padding: 8px 0;
  a {
    display: block;
    margin-left: 24px;
    @media (max-width: 768px) {
      margin-left: 16px;
    }
  }
`;
const AvailableOnHeader = styled.div`
  color: ${({ theme }) => theme.shade500};
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.04em;
  flex: 1;
  white-space: nowrap;
`;

const TrackRow = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  margin-bottom: 8px;
`;

const TrackTitle = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-weight: normal;

  color: ${({ theme }) => theme.shade500};
  font-size: 16px;
`;
const TrackNumber = styled(TrackTitle)`
  flex: none;
  color: ${({ theme }) => theme.shade400};

  margin-right: 16px;
`;
const Timestamp = styled.div`
  margin-left: 16px;
  font-variant: tabular-nums;
  color: ${({ theme }) => theme.shade400};

  font-size: 14px;
`;

// Component
const Name = () => {
  usePageView('*ville | Name');

  return (
    <PageWrapper>
      <CloseButton to='/'>
        <strong>X</strong>&nbsp;&nbsp;Close
      </CloseButton>
      {/* Title ( w/ album art ) */}
      <TitleWrapper>
        <AlbumImg src={NameCoverImg} alt='Name - Cover Art' />
        <TitleRow>
          <Expanded>
            <TitleSubhead>Album</TitleSubhead>
            <TitleHead>Name</TitleHead>
          </Expanded>
          <Timestamp>40:09</Timestamp>
        </TitleRow>
      </TitleWrapper>

      {/* Description */}
      <Description>
        Due to a mixture of perfectionism, apathy, regret, angst, deliberation, and various other
        life circumstances, *ville's first full-length album was assembled over an agonizing 10-year
        stretch—let's hope the wait was worth it.
      </Description>
      <Spacer height='24px' />
      <Description>
        <strong>Name</strong> is an 11-song set that aims to memorialize those close to us—friends
        and lovers that have shaped our lives. In the tunes, you'll find a healthy dose of romance,
        spite, desire, envy, and lots of other feels to get your soul longing. Enjoy!
      </Description>
      <Spacer height='32px' />

      {/* Available on... */}
      <AvailableOnRow>
        <AvailableOnHeader>Available on:</AvailableOnHeader>
        <a
          href='https://open.spotify.com/album/7DtZk6q0RZwlKLaMjdeRLN'
          target='_blank'
          rel='noopener noreferrer'
        >
          Spotify
        </a>
        <a
          href='https://geo.music.apple.com/album/name/1598932428?app=music'
          target='_blank'
          rel='noopener noreferrer'
        >
          Apple Music
        </a>
        {/* <a href='http://www.deezer.com/album/147191222' target='_blank' rel='noopener noreferrer'>
          Deezer
        </a> */}
        {/* <a href='http://www.tidal.com/album/140710035' target='_blank' rel='noopener noreferrer'>
          Tidal
        </a> */}
      </AvailableOnRow>

      {/* Tracks */}
      <TrackRow>
        {/* 1 - Catch */}
        <TrackNumber>01.</TrackNumber>
        <TrackTitle>Catch</TrackTitle>
        <Timestamp>03:55</Timestamp>
      </TrackRow>
      {/* 2 - Doubting Thomas */}
      <TrackRow>
        <TrackNumber>02.</TrackNumber>
        <TrackTitle>Doubting Thomas</TrackTitle>
        <Timestamp>03:35</Timestamp>
      </TrackRow>
      {/* 3 - Name */}
      <TrackRow>
        <TrackNumber>03.</TrackNumber>
        <TrackTitle>Name</TrackTitle>
        <Timestamp>04:38</Timestamp>
      </TrackRow>
      {/* 4 - Were You Mine */}
      <TrackRow>
        <TrackNumber>04.</TrackNumber>
        <TrackTitle>Were You Mine</TrackTitle>
        <Timestamp>03:23</Timestamp>
      </TrackRow>
      {/* 5 - Wreck My Heart */}
      <TrackRow>
        <TrackNumber>05.</TrackNumber>
        <TrackTitle>Wreck My Heart</TrackTitle>
        <Timestamp>04:55</Timestamp>
      </TrackRow>
      {/* 6 - Dancer */}
      <TrackRow>
        <TrackNumber>06.</TrackNumber>
        <TrackTitle>Dancer</TrackTitle>
        <Timestamp>04:20</Timestamp>
      </TrackRow>
      {/* 7 - By Her Side */}
      <TrackRow>
        <TrackNumber>07.</TrackNumber>
        <TrackTitle>By Her Side</TrackTitle>
        <Timestamp>03:34</Timestamp>
      </TrackRow>
      {/* 8 - Second Best */}
      <TrackRow>
        <TrackNumber>08.</TrackNumber>
        <TrackTitle>Second Best</TrackTitle>
        <Timestamp>03:26</Timestamp>
      </TrackRow>
      {/* 9 - Home */}
      <TrackRow>
        <TrackNumber>09.</TrackNumber>
        <TrackTitle>Home</TrackTitle>
        <Timestamp>02:11</Timestamp>
      </TrackRow>
      {/* 10 - I Want It All */}
      <TrackRow>
        <TrackNumber>10.</TrackNumber>
        <TrackTitle>I Want It All</TrackTitle>
        <Timestamp>03:55</Timestamp>
      </TrackRow>
      {/* 11 - Hollyby */}
      <TrackRow>
        <TrackNumber>11.</TrackNumber>
        <TrackTitle>Hollyby</TrackTitle>
        <Timestamp>02:11</Timestamp>
      </TrackRow>
    </PageWrapper>
  );
};
export default Name;
