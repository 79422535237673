import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// Styles
import { ThemeProvider } from 'styled-components/macro';
import defaultTheme from './styles/defaultTheme';
import Normalize from './styles/Normalize';
import BaseStyles from './styles/BaseStyles';
// Pages
import Name from './pages/Name';
import CrookedLittleHouse from './pages/CrookedLittleHouse';
import Landing from './pages/Landing';

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Normalize />
      <BaseStyles />
      <Router>
        <Landing />
        <Route path='/name' exact component={Name} />
        <Route path='/crooked-little-house' exact component={CrookedLittleHouse} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
