import { createGlobalStyle, css } from 'styled-components/macro';

const BodyScroll = createGlobalStyle<{ disabled: boolean }>`
${({ disabled }) =>
  disabled
    ? css`
        html,
        body {
          overflow: hidden;
        }
      `
    : css`
        html,
        body {
          -webkit-overflow-scrolling: touch;
          overflow-x: hidden;
          overflow-y: scroll;
        }
      `}
`;
export default BodyScroll;
