import React, { useRef, useEffect, useCallback, useMemo, CSSProperties, useState } from 'react';
import styled from 'styled-components/macro';

import { useLocation, Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

import BodyScroll from '../../styles/BodyScroll';
import Spacer from '../../common/Spacer';
import NameCoverImg from '../../img/name-cover.jpg';
import CrookedLittleHouseCoverImg from '../../img/crooked-little-house-cover.jpg';
import usePageView from '../../hooks/usePageView';

// Styles
const WordMark = styled.div`
  z-index: 1000;
  position: fixed;
  top: 48px;
  left: 64px;
  font-size: 64px;
  @media (max-width: 768px) {
    top: 36px;
    left: 48px;
    font-size: 52px;
  }
  @media (max-width: 512px) {
    top: 16px;
    left: 16px;
    font-size: 36px;
  }

  text-shadow: 0px 0.166035px 3.873px rgba(232, 232, 231, 0.0756322),
    0px 0.399006px 8.53433px rgba(232, 232, 231, 0.0853382),
    0px 0.751293px 14.7708px rgba(232, 232, 231, 0.0818974),
    0px 1.34018px 24.3295px rgba(232, 232, 231, 0.0763203),
    0px 2.50666px 42.1573px rgba(232, 232, 231, 0.0785255), 0px 6px 94px rgba(232, 232, 231, 0.13);
`;
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  padding: 164px calc((100% - 1024px) / 2) 24px;
  @media (max-width: 768px) {
    padding: 128px 0 24px;
  }
  @media (max-width: 512px) {
    padding: 80px 0 24px;
  }
`;

const AlbumRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: 512px) {
    flex-direction: column;
  }
`;
const AlbumDetails = styled.div`
  user-select: none;
  padding: 48px 32px;
  @media (max-width: 340px) {
    padding: 24px 16px;
  }
`;
const AlbumFader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(241, 241, 241, 0.85);
  border-radius: 6px;
`;
const AlbumBackface = styled.div`
  position: absolute;
  border-radius: 6px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.shade100};
  transform: rotateY(180deg);
  backface-visibility: hidden;
`;
const AlbumSubhead = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: ${({ theme }) => theme.shade500};
`;
const AlbumHead = styled.h2`
  margin: 0;
  font-weight: normal;
  font-size: 36px;
  line-height: 40px;
  color: ${({ theme }) => theme.shade700};
  @media (max-width: 512px) {
    font-size: 28px;
    line-height: 34px;
  }
  @media (max-width: 340px) {
    font-size: 24px;
    line-height: 30px;
  }
`;
const AlbumWrapper = styled(animated.div)`
  touch-action: none;

  z-index: 100;
  flex-shrink: 0;
  position: relative;
  height: 0;
  width: 384px;
  padding-bottom: 384px;
  transform-style: preserve-3d;
  /* backface-visibility: hidden; */
  border-radius: 6px;
  @media (max-width: 768px) {
    width: 50%;
    padding-bottom: 50%;
  }
  @media (max-width: 512px) {
    width: 80%;
    padding-bottom: 80%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    backface-visibility: hidden;
  }
  img.blurred {
    filter: blur(2px) saturate(0.9);
    opacity: 0;
    transition: opacity 500ms ${({ theme }) => theme.easeStandard};
    border-radius: 6px;
    backface-visibility: hidden;
  }
  box-shadow: 0px 0.67932px 2.44555px rgba(0, 0, 0, 0.0243888),
    0px 1.87823px 6.76164px rgba(0, 0, 0, 0.035), 0px 4.52206px 16.2794px rgba(0, 0, 0, 0.0456112),
    0px 15px 54px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;

  ${AlbumFader} {
    opacity: 0;
    transition: opacity 500ms ${({ theme }) => theme.easeStandard};
    backface-visibility: hidden;
  }

  ${AlbumDetails} {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translate3D(0, 5px, 0) scale(0.95);
    transition: opacity 500ms ${({ theme }) => theme.easeStandard},
      transform 500ms ${({ theme }) => theme.easeStandard};
    pointer-events: none;
    backface-visibility: hidden;
  }

  &:hover {
    box-shadow: 0px 2.26915px 3.01631px rgba(0, 0, 0, 0.0196802),
      0px 5.45308px 7.24861px rgba(0, 0, 0, 0.0282725), 0px 10.2677px 13.6485px rgba(0, 0, 0, 0.035),
      0px 18.3158px 24.3466px rgba(0, 0, 0, 0.0417275),
      0px 34.2576px 45.5376px rgba(0, 0, 0, 0.0503198), 0px 82px 109px rgba(0, 0, 0, 0.07);
    img.blurred {
      opacity: 1;
    }
    ${AlbumDetails} {
      opacity: 1;
      transform: translate3D(0, 0, 15px) scale(1);
      pointer-events: auto;
    }
    ${AlbumFader} {
      opacity: 1;
    }
  }
`;

const AlbumStub = styled.div`
  height: 320px;
  width: 320px;
  @media (max-width: 768px) {
    display: none;
  }

  border: 4px dashed ${({ theme }) => theme.shade400};
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  color: ${({ theme }) => theme.shade500};
`;

const AboutWrapper = styled.div`
  padding: 64px 32px;
  @media (max-width: 512px) {
    padding: 48px 16px;
  }
`;
const AboutHead = styled.div`
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 28px;
  color: ${({ theme }) => theme.shade500};
  @media (max-width: 512px) {
    font-size: 24px;
    margin-bottom: 12px;
  }
`;
const AboutCopy = styled.div`
  padding: 8px 0;
  text-align: justify;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  @media (max-width: 512px) {
    font-size: 16px;
    line-height: 22px;
  }
`;
const AboutMotto = styled.div`
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
  @media (max-width: 512px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const Expanded = styled.div`
  flex: 1;
`;
const Footer = styled.div`
  padding: 0 24px;
  text-align: center;
  font-size: 16px;
  color: ${({ theme }) => theme.shade400};
`;

// Album Hover Helpers ( Album Hover modified from: https://codesandbox.io/embed/rj998k4vmm )'
const trans = (x: any, y: any, s: any, tx: any, ty: any) =>
  `perspective(800px) rotateX(${x}deg) rotateY(${y}deg) scale(${s}) translate3D(${tx}px, ${ty}px, 0)`;
const useAlbumIsOpen = (albumName?: 'name' | 'crooked-little-house') => {
  const location = useLocation();
  const albumIsOpen = useMemo(
    () =>
      !albumName
        ? location.pathname.includes('name') || location.pathname.includes('crooked-little-house')
        : location.pathname.includes(albumName),
    [albumName, location.pathname]
  );
  return albumIsOpen;
};
const useAlbumHandler = (albumName: 'name' | 'crooked-little-house') => {
  const [spring, setSpring] = useSpring(() => ({ xys: [0, 0, 0.95, 0, 0] }));
  const albumRef = useRef<HTMLDivElement>(null);
  const albumIsOpen = useAlbumIsOpen(albumName);

  const handleHover = useCallback(
    (x: number, y: number) => {
      if (albumRef.current && !albumIsOpen) {
        albumRef.current.focus();
        const rotationFactor = 18;
        const { offsetLeft, offsetWidth, offsetTop, offsetHeight } = albumRef.current;
        const xOffset = offsetLeft + offsetWidth / 2;
        const yOffset = offsetTop + offsetHeight / 2;
        setSpring({
          xys: [(y - yOffset) / rotationFactor, -(x - xOffset) / rotationFactor, 1, 0, 0],
        });
      }
    },
    [albumIsOpen, setSpring]
  );

  const handleHoverReset = useCallback(() => {
    // Deferred because, strangely, scale sometimes wouldn't register
    !albumIsOpen && setTimeout(() => setSpring({ xys: [0, 0, 0.95, 0, 0] }), 1);
  }, [albumIsOpen, setSpring]);

  // Expand upon selecting 'Learn More'
  useEffect(() => {
    if (albumRef.current && albumIsOpen) {
      const { innerWidth, innerHeight } = window;

      const { offsetLeft, offsetWidth, offsetTop, offsetHeight } = albumRef.current;
      const xOffset = offsetLeft + offsetWidth / 2;
      const yOffset = offsetTop + offsetHeight / 2;

      const widthRatio = innerWidth / offsetWidth;
      const heightRatio = innerHeight / offsetHeight;
      const scaleFactor = Math.max(widthRatio, heightRatio);

      const translateX = xOffset - innerWidth / 2;
      const translateY = innerHeight / 2 - (yOffset - window.scrollY);
      // Deferred because, strangely, scale sometimes wouldn't register
      setTimeout(
        () =>
          setSpring({
            xys: [0, -180, scaleFactor, translateX / scaleFactor, translateY / scaleFactor],
          }),
        1
      );
    } else {
      // Resetting when switching back to landing
      handleHoverReset();
    }
  }, [albumIsOpen, handleHoverReset, setSpring]);

  // return [albumRef, spring, handleHover, handleHoverReset] as const;
  return {
    ref: albumRef,
    onMouseMove: ({ clientX, clientY }: any) => handleHover(clientX, clientY),
    onTouchMove: ({ touches }: any) => handleHover(touches[0].clientX, touches[0].clientY),
    onTouchEnd: () => !albumIsOpen && handleHoverReset(),
    onMouseLeave: () => !albumIsOpen && handleHoverReset(),
    style: {
      transform: spring.xys.interpolate(trans as any),
      zIndex: albumIsOpen ? 200 : 100,
    } as CSSProperties,
  };
};

// Component
const Landing = () => {
  usePageView('*ville | Simple. Honest. Music');
  const albumIsOpen = useAlbumIsOpen();
  // const [albumRef, spring, handleHover, handleHoverReset] = useAlbumHandler();
  const nameSpread = useAlbumHandler('name');
  const crookedLittleHouseSpread = useAlbumHandler('crooked-little-house');

  // const [spring, setSpring] = useSpring(() => ({ xys: [0, 0, 0.95, 0, 0] }));
  // const albumRef = useRef<HTMLDivElement>(null);
  // const location = useLocation();
  // const albumIsOpen = useMemo(
  //   () => location.pathname.includes('crooked-little-house'),
  //   [location.pathname]
  // );

  // const handleHover = useCallback(
  //   (x: number, y: number) => {
  //     if (albumRef.current && !albumIsOpen) {
  //       albumRef.current.focus();
  //       const rotationFactor = 18;
  //       const { offsetLeft, offsetWidth, offsetTop, offsetHeight } = albumRef.current;
  //       const xOffset = offsetLeft + offsetWidth / 2;
  //       const yOffset = offsetTop + offsetHeight / 2;
  //       setSpring({
  //         xys: [(y - yOffset) / rotationFactor, -(x - xOffset) / rotationFactor, 1, 0, 0],
  //       });
  //     }
  //   },
  //   [albumIsOpen, setSpring]
  // );

  // const handleHoverReset = useCallback(() => {
  //   // Deferred because, strangely, scale sometimes wouldn't register
  //   !albumIsOpen && setTimeout(() => setSpring({ xys: [0, 0, 0.95, 0, 0] }), 1);
  // }, [albumIsOpen, setSpring]);

  // // Expand upon selecting 'Learn More'
  // useEffect(() => {
  //   if (albumRef.current && albumIsOpen) {
  //     const { innerWidth, innerHeight } = window;

  //     const { offsetLeft, offsetWidth, offsetTop, offsetHeight } = albumRef.current;
  //     const xOffset = offsetLeft + offsetWidth / 2;
  //     const yOffset = offsetTop + offsetHeight / 2;

  //     const widthRatio = innerWidth / offsetWidth;
  //     const heightRatio = innerHeight / offsetHeight;
  //     const scaleFactor = Math.max(widthRatio, heightRatio);

  //     const translateX = xOffset - innerWidth / 2;
  //     const translateY = innerHeight / 2 - (yOffset - window.scrollY);
  //     // Deferred because, strangely, scale sometimes wouldn't register
  //     setTimeout(
  //       () =>
  //         setSpring({
  //           xys: [0, -180, scaleFactor, translateX / scaleFactor, translateY / scaleFactor],
  //         }),
  //       1
  //     );
  //   } else {
  //     // Resetting when switching back to landing
  //     handleHoverReset();
  //   }
  // }, [albumIsOpen, handleHoverReset, setSpring]);

  return (
    <>
      <BodyScroll disabled={albumIsOpen} />
      <WordMark>
        <strong>*</strong>ville
      </WordMark>

      <PageWrapper>
        <AlbumRow>
          {/* Album ( name ) */}
          <AlbumWrapper
            {...nameSpread}
            // ref={albumRef}
            // onMouseMove={({ clientX, clientY }) => handleHover(clientX, clientY)}
            // onTouchMove={({ touches }) => handleHover(touches[0].clientX, touches[0].clientY)}
            // onTouchEnd={() => !albumIsOpen && handleHoverReset()}
            // onMouseLeave={() => !albumIsOpen && handleHoverReset()}
            // style={{ transform: spring.xys.interpolate(trans as any) }}
          >
            <AlbumBackface />
            <img src={NameCoverImg} alt='Name - Cover Art' />
            <img className='blurred' src={NameCoverImg} alt='Name - Cover Art - Blurred' />
            <AlbumFader />
            <AlbumDetails>
              <AlbumSubhead>Album</AlbumSubhead>
              <Spacer height='16px' />
              <AlbumHead>Name</AlbumHead>
              <Spacer height='32px' />
              <Link to='/name'>Learn more</Link>
            </AlbumDetails>
          </AlbumWrapper>

          {/* Album ( crooked little house ) */}
          <AlbumWrapper
            {...crookedLittleHouseSpread}
            // ref={albumRef}
            // onMouseMove={({ clientX, clientY }) => handleHover(clientX, clientY)}
            // onTouchMove={({ touches }) => handleHover(touches[0].clientX, touches[0].clientY)}
            // onTouchEnd={() => !albumIsOpen && handleHoverReset()}
            // onMouseLeave={() => !albumIsOpen && handleHoverReset()}
            // style={{ transform: spring.xys.interpolate(trans as any) }}
          >
            <AlbumBackface />
            <img src={CrookedLittleHouseCoverImg} alt='Crooked Little House - Cover Art' />
            <img
              className='blurred'
              src={CrookedLittleHouseCoverImg}
              alt='Crooked Little House - Cover Art - Blurred'
            />
            <AlbumFader />
            <AlbumDetails>
              <AlbumSubhead>Album</AlbumSubhead>
              <Spacer height='16px' />
              <AlbumHead>Crooked Little House - Revisited</AlbumHead>
              <Spacer height='32px' />
              <Link to='/crooked-little-house'>Learn more</Link>
            </AlbumDetails>
          </AlbumWrapper>

          {/* <AlbumStub>Coming Soon</AlbumStub> */}
        </AlbumRow>

        {/* About the band */}
        <AboutWrapper>
          <AboutHead>About the band</AboutHead>
          <AboutCopy>
            Beginning in 2009 with a singular dream and a one-way bus ticket, *ville quickly grew
            into a dysfunctional 2-man collaboration doomed with its youthful ambition and career
            naiveté. The band produces an album about once every decade, so you can expect *ville to
            be topping charts no later than the year 2068.
          </AboutCopy>
          <AboutCopy>
            Although dreams change and life always finds a way of messing things up, *ville
            continues to live on—holding true to the motto...
          </AboutCopy>
          <AboutMotto>Simple. Honest. Music.</AboutMotto>
        </AboutWrapper>

        {/* Footer */}
        <Expanded />
        <Footer>
          <strong>©{new Date().getFullYear()} All rights reserved</strong>{' '}
          to&nbsp;soothe&nbsp;your&nbsp;aching&nbsp;ears.
        </Footer>
      </PageWrapper>
    </>
  );
};
export default Landing;
